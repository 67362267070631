// Force scroll to top
if (location.hash) {
    window.scrollTo(0, 0);
    setTimeout(function() {
        window.scrollTo(0, 0);
    }, 1);
}

jQuery(document).ready(function ($) {

    // Payment flows
    const subscriptionTrigger = $('#report-subscription');
    const purchaseTrigger = $('#report-purchase');
    const listOfContentsPaymentTrigger = $('.paywall-modal-trigger');
    const scriptUrl = config.paymentUrl;

    const defaultAttributes = {
        'id': 'paymentmodule',
        'data-color-gradient': 'linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%), #c6312c',
        'data-color': '#c6312c',
        'data-logged-in': $('input[name=isUserLoggedIn]').val(),
        'data-login-url': $('input[name=loginUrl]').val(),
        'data-payment-country': 'EE',
        'data-form-lang': 'et',
    };

    function setAttributes (element, attributes) {
        for (var key in attributes) {
            element.setAttribute(key, attributes[key]);
        }
    }

    subscriptionTrigger.click(function() {
        onPaymentClick(this);
    });

    purchaseTrigger.click(function() {
        onPaymentClick(this);
    });

    listOfContentsPaymentTrigger.click(onLeftMenuItemClick);

    function onPaymentClick (buttonElement) {
        (() => {
            const paymentModuleSelector = document.getElementById('paymentmodule');
            if (document.contains(paymentModuleSelector)) {
                paymentModuleSelector.remove();
            }
            const priceGroupId = buttonElement.getAttribute('data-price-group-id');
            const paymentModule = document.createElement('div');
            document.body.appendChild(paymentModule);
            setAttributes(paymentModule, defaultAttributes);
            paymentModule.setAttribute('data-products', `[{ "priceGroupId": "${priceGroupId}", "quantity": "1" }]`);
            const script = document.createElement('script');
            document.body.appendChild(script);
            script.addEventListener('load', () => {
                document.getElementById('paymentmodule').classList.add('show');
            });
            script.src = scriptUrl;
        })();
    }

    function onLeftMenuItemClick(e) {
        e.preventDefault();
        subscriptionTrigger.first().trigger('click');
    }

    const getUrlParameter = name => {
        const regex = new RegExp(`[\\?&]${name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')}=([^&#]*)`);
        const results = regex.exec(window.location.search);
        return results === null
            ? false
            : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    const initPaymentModule = () => {
        const paymentModuleURL = scriptUrl;
        const successParamValue = getUrlParameter('success');
        if (successParamValue) {
            loadPaymentModule(paymentModuleURL);
            updateJwtToken();
        }
    };

    const updateJwtToken = () => {
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'POST',
            data: {
                'action': 'update_jwt_token',
            },
            success: function (response) {
                if (response) {
                    setTimeout(function() {
                        window.location.href = window.location.href.split('?')[0];
                    }, 5000);
                }
            },
            error: function (res) {
                console.log(res);
            }
        });
    };

    const loadPaymentModule = src => {
        return new Promise((resolve, reject) => {
            const scriptTag = document.createElement('script');
            scriptTag.type = 'text/javascript';
            scriptTag.src = src;
            scriptTag.onload = () => resolve();
            scriptTag.onerror = () => reject();
            document.body.appendChild(scriptTag);
        });
    };

    // Mobile menu
    let trigger = $('.menutoggle');
    let isClosed = false;

    trigger.click(function () {
        toggleMenu();
    });
    function toggleMenu() {
        let HTML = $('HTML');
        if (isClosed) {
            if (HTML.hasClass('menu-open')) {
                HTML.removeClass('menu-open');
            }
            trigger.removeClass('is-open');
            trigger.addClass('is-closed');
            isClosed = false;
        } else {
            HTML.addClass('menu-open');
            trigger.removeClass('is-closed');
            trigger.addClass('is-open');
            isClosed = true;
        }
    }

    // Full screen mode
    let fs_trigger = $('#full-screen-trigger');
    let fs_isClosed = false;

    fs_trigger.click(function () {
        toggleFullScreen();
    });
    function toggleFullScreen() {
        let HTML = $('HTML');
        if (fs_isClosed) {
            if (HTML.hasClass('is-fullscreen')) {
                HTML.removeClass('is-fullscreen');
            }
            fs_trigger.removeClass('active');
            fs_isClosed = false;
        } else {
            HTML.addClass('is-fullscreen');
            fs_trigger.addClass('active');
            fs_isClosed = true;
        }
    }

    // Hide Header on on scroll down
    let didScroll;

    function fadeSection() {
        $('SECTION, FOOTER').each(function() {
            const top_of_object = $(this).position().top;
            const bottom_of_window = $(window).scrollTop() + $(window).height();
            if( bottom_of_window > top_of_object ){
                if( ! $(this).hasClass('section-animate') ) {
                    $(this).addClass('section-animate');
                }
            }
        });
    }
    fadeSection();

    // Sections fade in + sticky
    $(window).scroll(function() {
        fadeSection();
        didScroll = true;
    });

    let elSelector = '.header-main';
    let element = document.querySelector(elSelector);

    let elHeight = 0,
        elTop = 0,
        dHeight = 0,
        wHeight = 0,
        wScrollCurrent = 0,
        wScrollBefore = 0,
        wScrollDiff = 0;

    window.addEventListener('scroll', function() {
        elHeight = element.offsetHeight;
        dHeight = document.body.offsetHeight;
        wHeight = window.innerHeight;
        wScrollCurrent = window.pageYOffset;
        wScrollDiff = wScrollBefore - wScrollCurrent;
        elTop = parseInt(window.getComputedStyle(element).getPropertyValue('top')) + wScrollDiff;

        if (wScrollCurrent <= 0) {
            element.style.top = '0px';
        }
        else if (wScrollDiff > 0) {
            element.style.top = (elTop > 0 ? 0 : elTop) + 'px';
        }
        else if (wScrollDiff < 0) {
            if (wScrollCurrent + wHeight < dHeight - elHeight) {
                element.style.top = (Math.abs(elTop) > elHeight ? -elHeight : elTop) + 'px';
            }
        }
        wScrollBefore = wScrollCurrent;
    });

    // Collapsible
    const collapsible = $('.collapsible');

    if (collapsible.length > 0) {
        const collapsibleTrigger = collapsible.find('.toggle');
        collapsible.find('LI:first-child').addClass('expanded');
        collapsibleTrigger.click(function () {
            this.parentElement.classList.toggle('expanded');
        });
    }

    // Tabs switcher
    const tabs = $('.main-tabs-wrapper');

    if (tabs.length > 0) {

        const tabsTriggerSelector = tabs.find('.tabs > LI > A');
        const tabsContentSelector = tabs.find('.tabs-content > DIV');
        let hash = window.location.hash;

        handleTabs(hash);

        tabsTriggerSelector.click(function(event) {
            event.preventDefault();
            window.location.hash = this.hash;
            handleTabs(window.location.hash);
        });

        $(window).on('hashchange', function() {
            handleTabs(window.location.hash);
        });

        function handleTabs (hash) {
            if (!hash) {
                const first = tabsTriggerSelector.first().attr('href');
                hash = first;
                window.location.hash = first;
            }
            const selectedContent = '[data-key=' + hash.substr(1) + ']';
            const selectedTrigger = '[href="' + hash + '"]';
            let timer1 = 0;
            let timer2 = 0;
            $('.tabs-content').addClass('transition');
            timer2 = setTimeout(function () {
                tabsContentSelector.removeClass('active');
                tabsTriggerSelector.removeClass('active');
                $(selectedContent + ', ' + selectedTrigger).addClass('active');
            }, 200);
            timer1 = setTimeout(function () {
                $('.tabs-content').removeClass('transition');
            }, 400);
        }
    }

    // Image switcher for introduction section
    const introduction = $('.main-introduction');

    if (introduction.length > 0) {
        function cycleImages(){
            let images = $('.main-introduction .images .image'),
                now    = images.filter(':visible'),
                next   = now.next().length ? now.next() : images.first(),
                speed  = 750;

            now.fadeOut(speed);
            next.fadeIn(speed);
        }
        setInterval(function() {
            cycleImages();
        }, 8000);
    }

    // Button tooltip
    let tooltipTrigger = $('.button-tooltip .button');
    let tooltipContent = $('.button-tooltip-content');
    tooltipTrigger.click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).closest('.button-tooltip').toggleClass('open');
    });
    tooltipContent.click(function () {
        $(this).closest('.button-tooltip').removeClass('open');
    });

    $('BODY').click(function () {
        let tooltips = document.querySelectorAll('.button-tooltip');
        $(tooltips).removeClass('open');
    });

    // Content sliders

    $('.content-slider').each(function () {
        const settings = {
            speed: 300,
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: true,
            dots: false,
            adaptiveHeight: true,
        };
        const type = $(this).data('type');
        if (type === 'logos') {
            settings.slidesToShow = 6;
            settings.slidesToScroll = 1;
            settings.infinite = true;
            settings.autoplay = true;
            settings.autoplaySpeed = 2000;
            settings.responsive = [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 380,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ];
        }
        if (type === 'reviews') {
            settings.slidesToShow = 3;
            settings.slidesToScroll = 3;
            settings.infinite = false;
            settings.autoplay = true;
            settings.autoplaySpeed = 10000;
            settings.responsive = [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ];
        }
        $(this).slick(settings);
    });
    initPaymentModule();
});
